export const restaurantLayoutHeadRows = [
  { name: 'restaurantLayoutName' },
  { name: 'restaurantLayoutOnlineReservations' },
  { name: 'restaurantLayoutActive' },
  { name: '' },
];

export const restaurantLayoutFormFields = {
  name: 'name',
  layouts: 'layouts',
  online_booking: 'online_booking',
  active: 'active',
};

export const restaurantLayoutModalFormFields = {
  name: 'name',
  online_booking: 'online_booking',
  active: 'active',
  weight: 'weight',
};

export const roomModalFormFields = {
  name: 'name',
  layout: 'layout_id',
  online_booking: 'online_booking',
  archive: 'archive',
  collapsed: 'collapsed',
  status: 'status',
  weight: 'weight',
};

export const tableModalFormFields = {
  name: 'name',
  room_id: 'room_id',
  min_guests: 'min_guests',
  max_guests: 'max_guests',
  online_booking: 'online_booking',
  shape: 'shape',
  archive: 'archive',
  status: 'status',
};

export const roomFormFields: any = {
  online_booking: 'online_booking',
  archive: 'archive',
  collapsed: 'collapsed',
  tables: 'tables',
  status: 'status',
  weight: 'weight',
};

export enum TableManagementStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Archive = 'ARCHIVE',
}

export enum TableShapes {
  SquareAround = 'SQUARE_AROUND',
  SquareSide = 'SQUARE_SIDE',
  Round = 'ROUND',
  Oval = 'OVAL',
  RectangleAround = 'RECTANGLE_AROUND',
  RectangleSide = 'RECTANGLE_SIDE',
}
